import React from 'react';
import './Testimonials.css';
import Test1 from './../../Assets/testimonial1.jpg';
import Test2 from './../../Assets/testimonail2.jpg';
import Test3 from './../../Assets/testimonial3.jpg';

const Testimonials = () => {
  return (
    <div className='testimonials' id='testimonials'>
      <div className="cont">
        <h2>Testimonials</h2>
        <span className='line'></span>
        <div className="content">
            <div className="card">
                <img src={Test1} alt="Testimonial Avatar" />
                <p>"Working with Felix was a game-changer! His Figma designs were not only sleek and modern but incredibly intuitive. He went above and beyond to ensure every detail was perfect. The website he developed for us was fast, responsive, and visually stunning. I was truly impressed with his professionalism and skill throughout the process."</p>
                <p>Jared K.W</p>
                <p>Founder - Jared Arts</p>
            </div>
            <div className="card">
                <img src={Test2} alt="Testimonial Avatar" />
                <p>"Felix is an outstanding web developer and Figma designer! He transformed our ideas into a beautiful, functional website that exceeded our expectations. His attention to detail, creativity, and technical expertise made the entire experience seamless. We couldn’t be happier with the result!"</p>
                <p>Lilian A.O</p>
                <p>Manager - Wasafi Boutique</p>
            </div>
            <div className="card">
                <img src={Test3} alt="Testimonial Avatar" />
                <p>"Felix’s design and development work was beyond impressive. His Figma mockups were spot-on, and he translated them perfectly into a fast, user-friendly website. I appreciated his clear communication, patience, and dedication to delivering a top-notch final product. Highly recommended!"</p>
                <p>Rashid J.P</p>
                <p>C.E.O - Rashid Photography</p>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonials
