import React from "react";
import "./Footer.css";
import { FaWhatsapp, FaTelegram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const iconStyle = { color: "white", fontSize: "24px" };

  // Function to handle email click
  const handleEmailClick = () => {
    window.location.href = "mailto:flexxmosh@gmail.com";
  };

  return (
    <div className="footer">
      <div className="container">
        <div className="sect">
          <div className="hd">Let Socialize On:</div>
          <div className="socials">
            <a
              href="https://wa.me/254768555319"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FaWhatsapp style={iconStyle} />
            </a>
            <a
              href="https://t.me/flexxmosh"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FaTelegram style={iconStyle} />
            </a>
            <a
              href="https://x.com/flexxmosh"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FaXTwitter style={iconStyle} />
            </a>
          </div>
        </div>
        <div className="sect">
          <p>&copy; {currentYear} | Felix Omondi</p>
        </div>
        <div className="sect email">
          <button onClick={handleEmailClick}>FlexxMosh@Gmail.com</button>
        </div>
      </div>
    </div>
  );
};

export default Footer;
