import { React } from "react";
import Navbar from "./Components/Navbar/Navbar";
import Hero from './Components/Hero/Hero';
import About from "./Components/About/About";
import Testimonials from "./Components/Testimonials/Testimonials";
import Portfolio from "./Components/Portfolio/Portfolio";
import Footer from "./Components/Footer/Footer";
function App() {
  return (
    <div className="App">
      <Navbar />
      <Hero />
      <About />
      <Testimonials />
      <Portfolio />
      <Footer />
    </div>
  );
}

export default App;
