import React, { useCallback, useEffect } from "react";
import "./Portfolio.css";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from 'embla-carousel-autoplay';

const Portfolio = () => {

  const [emblaRef, emblaApi] = useEmblaCarousel({loop:true}, [Autoplay({delay: 4000})]);

  useEffect(() => {
    if (emblaApi) {
      console.log(emblaApi.slideNodes()); //Access API      
    }
  }, [emblaApi])

  const scrollPrev = useCallback(()=> {
    if (emblaApi) emblaApi.scrollPrev()
  }, [emblaApi])

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext()
  }, [emblaApi])
  return (
    <div className="portfolio" id="portfolio" ref={emblaRef}>
      <div className="embla-container">
      <div className="video">
        <div className="lside">
          <h3>
            Check out my <br /> Previous Work
          </h3>
          <p>Click on the Video to view full screen</p>
        </div>
        <div className="rside">
          <iframe
            width="570"
            height="380"
            src="https://www.youtube.com/embed/l5u1t0nkL50"
            title="WordPress Website Design"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div className="video">
        <div className="lside">
          <h3>
            Check out my <br /> Previous Work
          </h3>
          <p>Click on the Video to view full screen</p>
        </div>
        <div className="rside">
          <iframe
            width="570"
            height="380"
            src="https://www.youtube.com/embed/a49laoi55qY"
            title="WordPress Website Design"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div className="video">
        <div className="lside">
          <h3>
            Check out my <br /> Previous Work
          </h3>
          <p>Click on the Video to view full screen</p>
        </div>
        <div className="rside">
          <iframe
            width="570"
            height="380"
            src="https://www.youtube.com/embed/l5u1t0nkL50"
            title="WordPress Website Design"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div className="video">
        <div className="lside">
          <h3>
            Check out my <br /> Previous Work
          </h3>
          <p>Click on the Video to view full screen</p>
        </div>
        <div className="rside">
          <iframe
            width="570"
            height="380"
            src="https://www.youtube.com/embed/YrVr3BEG0Kw"
            title="WordPress Website Design"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      </div>
      <div className="scrollBtn">
        <button className="embla-" onClick={scrollPrev}>&lt;</button>
        <button className="embla-" onClick={scrollNext}>&gt;</button>
      </div>
    </div>
  );
};

export default Portfolio;
