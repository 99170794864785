import React from 'react';
import './Hero.css';

const Hero = () => {
  return (
    <div className='hero' id='home'>
      <div className="content">
        <p>Email Me:</p>
        <p>FlexxMosh@Gmail.com</p>
        <p>Web Design</p>
        <p>& Development</p>
        <a href="https://wa.me/254768555319" target="_blank" rel="noopener noreferrer">
        <button>Free Consultation</button>
        </a>
        
      </div>
    </div>
  )
}

export default Hero
