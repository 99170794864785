import React from "react";
import "./About.css";
import Avatar from "./../../Assets/avatar.jpg";

const About = () => {
  return (
    <div className="about" id="about">
      <div className="container">
        <div className="col-1">
        <img src={Avatar} alt="" />
        </div>
        <div className="col-2">
          <h1>About Me</h1>
          <span className="line"></span>
          <h2>I'm Felix Omondi</h2>
          <h4>Front-End Designer/Developer</h4>
          <p>
            As a versatile web developer and designer, I bring you a
            comprehensive skill set to create visually appealing, functional,
            and responsive web applications. 
            <br /> <br /> 
            I startoff with design in <strong>Figma</strong> and
            develop it from the ground up using <strong>HTML5</strong>, <strong>CSS3</strong>, <strong>JavaScript</strong>, <strong>React JS</strong>, and <strong>Next.JS</strong>.
            <br /> <br /> 
            I can further develop it using popular CMS like <strong>WordPress</strong> and <strong>WebFlow</strong>.
          </p>
          <p>I take a lot of pride in building dynamic, responsive web
            applications with enhanced UX/UI.
          </p>
          <a href="https://wa.me/254768555319" target="_blank" rel="noopener noreferrer">
            <button>Contact Me Now</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;
